import {
    AccountCircle as AccountCircleIcon,
    Announcement as AnnouncementIcon,
    Class as ClassIcon,
    CollectionsBookmark as CollectionsBookmarkIcon,
    Dashboard as DashboardIcon,
    Equalizer as EqualizerIcon,
    LocalLibrary as LocalLibraryIcon,
    Newspaper as NewspaperIcon, Quiz as QuizIcon,
    School as SchoolIcon,
    SupervisorAccount as SupervisorAccountIcon,
    VideoLibrary as VideoLibraryIcon
} from "@mui/icons-material";
import { Divider, ListItemIcon, ListSubheader, Theme, styled, useMediaQuery } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AUTHORITIES } from "config/constants";
import { useAppDispatch, useAppSelector } from "config/store";
import { default as React, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { hasAnyAuthority } from "shared/auth/private-route";
import { AuthenticationState } from "shared/reducers/authentication";


import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { EdoParentState, getParentChildren } from "shared/reducers/entities/edo-parent.reducer";
import { EdoStudentState, getStudentClasses } from "shared/reducers/entities/edo-student.reducer";
import { EdoTeacherState, getTeacherClasses } from "shared/reducers/entities/edo-teacher.reducer";
import isSelected from "shared/util/isSelected";

const CustomDrawer = styled(MuiDrawer)<{ component?: React.ElementType }>(({ theme }) => ({
    '& .MuiList-root': {
        marginTop: "15px",
        marginBottom: "15px",
    },
    '& .MuiListItem-root': {
        overflow: 'hidden',
        borderRadius: "5px",
        marginRight: "15px",
        marginLeft: "15px",
        width: "auto"
    },
    '& .MuiListItem-root.Mui-selected': {
        backgroundColor: `${theme.palette.primary.main}3E`,
    },
    '& .MuiListItem-root:not(:last-child)': {
        marginBottom: "10px",
    },
    '& .MuiListItemButton-root': {
        paddingTop: "8px",
        paddingBottom: "8px",
    },

    '& .MuiTypography-root': {
        color: theme.palette.text.primary
    },
    '& .MuiListItemIcon-root': {
        minWidth: "40px",
    },
    '& .MuiListItem-root:hover': {
        backgroundColor: `${theme.palette.primary.main}1E`,
        '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
        },
    },
    '& .MuiListSubheader-root': {
        backgroundColor: 'transparent',
    },
    '& .MuiAppBar-root': {
        backgroundColor: 'transparent',
    },
    '& .MuiPaper-root': {
        backgroundColor: 'background.default',

    },
    '& .MuiDrawer-root': {
        backgroundColor: 'background.default',
    }
}));

export const DRAWER_WIDTH = 280;

interface IDrawerProps {
    window?: () => Window;
    mobileOpen: boolean;
    onDrawerToggle: () => void;
}

const Drawer = (props: IDrawerProps) => {
    const { window, mobileOpen, onDrawerToggle: handleDrawerToggle } = props;

    const location = useLocation();
    const dispatch = useAppDispatch();
    const { account } = useAppSelector<AuthenticationState>(
        (state) => state.authentication
    );
    const { children } = useAppSelector<EdoParentState>(state => state.edoParent);
    const { classes: teacherClasses } = useAppSelector<EdoTeacherState>(state => state.edoTeacher);
    const { classes: studentClasses } = useAppSelector<EdoStudentState>(state => state.edoStudent);
    const isMediaSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        if (!account.id) return;
        if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_TEACHER])) {
            dispatch(getTeacherClasses({
                id: account.id,
                page: 0,
                size: 1000,
                sort: ['year.year,ASC', 'section,ASC']
            }));
        } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT])) {
            dispatch(getStudentClasses({
                id: account.id,
                page: 0,
                size: 1000,
                sort: ['year.year,ASC', 'section,ASC']
            }));
        } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT])) {
            dispatch(getParentChildren(account.id));
        }
    }, [account.id]);

    let classes = useMemo(() => {
        if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_TEACHER])) {
            return teacherClasses;
        } else if (hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_STUDENT])) {
            return studentClasses;
        }
        return [];
    }, [account, teacherClasses, studentClasses]);

    const drawer = (
        <>
            <Toolbar sx={{ height: "74px" }}>
                <Typography
                    display="block"
                    sx={{ textDecoration: "none" }}
                    variant="h6"
                    noWrap
                    component={Link}
                    to={hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT]) ? "/parents" : "/"}
                    color="inherit"
                    onClick={handleDrawerToggle}
                >
                    eDocendo
                </Typography>
            </Toolbar>
            {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN,
            AUTHORITIES.ROLE_VICE,
            AUTHORITIES.ROLE_TEACHER,
            AUTHORITIES.ROLE_STUDENT,
            AUTHORITIES.ROLE_SUPPORT_TEACHER]) ? (
                <>
                    <Divider />
                    <List>
                        {[{
                            text: 'Dashboard',
                            icon: <DashboardIcon />,
                            href: "/",
                            roles: [
                                AUTHORITIES.ROLE_STUDENT,
                            ],
                            selectPattern: "/"
                        }, {
                            text: 'Feed',
                            icon: <NewspaperIcon />,
                            href: "/feed",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN,
                                AUTHORITIES.ROLE_VICE,
                                AUTHORITIES.ROLE_TEACHER,
                                AUTHORITIES.ROLE_STUDENT,
                                AUTHORITIES.ROLE_SUPPORT_TEACHER,
                            ],
                            selectPattern: "/feed"
                        }, {
                            text: 'Il mio archivio',
                            icon: <CollectionsBookmarkIcon />,
                            href: "/repository",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN,
                                AUTHORITIES.ROLE_VICE,
                                AUTHORITIES.ROLE_TEACHER,
                            ],
                            selectPattern: "/repository/*"
                        }, {
                            text: "I miei test",
                            icon: <QuizIcon />,
                            href: "/quizzes",
                            roles: [AUTHORITIES.ROLE_TEACHER],
                            selectPattern: "/quizzes/*"
                        }].map((item, index) => (
                            hasAnyAuthority(account.roles, item.roles) ? (
                                <ListItem
                                    key={item.text}
                                    disablePadding
                                    component={Link}
                                    to={item.href}
                                    selected={isSelected(
                                        location,
                                        item.selectPattern
                                    )}
                                    onClick={handleDrawerToggle}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            ) : null
                        ))}
                    </List>
                </>
            ) : null}
            {hasAnyAuthority(account.roles, [
                AUTHORITIES.ROLE_ADMIN,
                AUTHORITIES.ROLE_SUPPORT_TEACHER,
                AUTHORITIES.ROLE_TEACHER,
                AUTHORITIES.ROLE_VICE,
                AUTHORITIES.ROLE_STUDENT
            ]) ? (
                <>
                    <Divider />
                    <List>
                        {[{
                            text: 'Le mie classi',
                            icon: <SchoolIcon />,
                            href: "/classes",
                            roles: [
                                AUTHORITIES.ROLE_VICE,
                                AUTHORITIES.ROLE_TEACHER
                            ],
                            selectPattern: "/classes"
                        }, {
                            text: 'Dispense',
                            icon: <LocalLibraryIcon />,
                            href: "/wiki",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN,
                                AUTHORITIES.ROLE_SUPPORT_TEACHER,
                                AUTHORITIES.ROLE_TEACHER,
                                AUTHORITIES.ROLE_VICE,
                                AUTHORITIES.ROLE_STUDENT,
                                AUTHORITIES.ROLE_PARENT,
                            ],
                            selectPattern: "/wiki/*"
                        }, {
                            text: 'Videoteca',
                            icon: <VideoLibraryIcon />,
                            href: "/videostore",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN,
                                AUTHORITIES.ROLE_SUPPORT_TEACHER,
                                AUTHORITIES.ROLE_TEACHER,
                                AUTHORITIES.ROLE_VICE,
                                AUTHORITIES.ROLE_STUDENT,
                                AUTHORITIES.ROLE_PARENT,
                            ],
                            selectPattern: "/videostore/*"
                        }, {
                            text: "Admin",
                            icon: <SupervisorAccountIcon />,
                            href: "/admin",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN,
                            ],
                            selectPattern: "/admin/*"
                        }].map((item, index) => (
                            hasAnyAuthority(account.roles, item.roles) ? (
                                <ListItem
                                    key={item.text}
                                    disablePadding
                                    component={Link}
                                    to={item.href}
                                    selected={isSelected(
                                        location,
                                        item.selectPattern
                                    )}
                                    onClick={handleDrawerToggle}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            ) : null
                        ))}
                    </List>
                </>
            ) : null}
            {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_VICE]) ? (
                <>
                    <Divider />
                    <List subheader={<ListSubheader>Amministrazione</ListSubheader>}>
                        {[{
                            text: 'Gestione circolari',
                            icon: <AnnouncementIcon />,
                            href: "/circulars-management",
                            roles: [AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_VICE],
                            selectPattern: "/circulars-management/*"
                        }, {
                            text: 'Controllo attività docenti',
                            icon: <EqualizerIcon />,
                            href: "/teachers-supervision",
                            roles: [
                                AUTHORITIES.ROLE_ADMIN, AUTHORITIES.ROLE_VICE
                            ],
                            selectPattern: "/teachers-supervision/*"
                        }].map((item, index) => (
                            hasAnyAuthority(account.roles, item.roles) ? (
                                <ListItem
                                    key={item.text}
                                    disablePadding
                                    component={Link}
                                    to={item.href}
                                    selected={isSelected(
                                        location,
                                        item.selectPattern
                                    )}
                                    onClick={handleDrawerToggle}
                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            ) : null
                        ))}
                    </List>
                </>
            ) : null}
            {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_PARENT]) ? (
                <>
                    <Divider />
                    <List subheader={<ListSubheader>Figli</ListSubheader>}>
                        {children?.entities?.map(child => (
                            <ListItem
                                key={child.userId}
                                disablePadding
                                to={`/parents/children/${child.userId}`}
                                component={Link}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemButton selected={isSelected(location, `/parents/children/${child.userId}/*`)}>
                                    <ListItemIcon>
                                        <AccountCircleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={child.user.firstName + " " + child.user.lastName} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : null}
            {hasAnyAuthority(account.roles, [AUTHORITIES.ROLE_TEACHER, AUTHORITIES.ROLE_STUDENT]) && classes.length !== 0 ? (
                <>
                    <Divider />
                    <List subheader={<ListSubheader>Le mie classi</ListSubheader>}>
                        {classes.map(edoClass => (
                            <ListItem
                                key={edoClass.id}
                                disablePadding
                                to={`/classes/${edoClass.id}`}
                                component={Link}
                                onClick={handleDrawerToggle}
                            >
                                <ListItemButton selected={isSelected(location, `/classes/${edoClass.id}`)}>
                                    <ListItemIcon>
                                        <ClassIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${edoClass.year.year}°${edoClass.section}`}
                                        secondary={edoClass.school.code}
                                        primaryTypographyProps={{ variant: "body1" }}
                                        secondaryTypographyProps={{ variant: "body2" }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : null}
        </>
    );


    return (
        <>
            <CustomDrawer
                container={container}
                variant="temporary"
                open={mobileOpen && isMediaSm}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                    },
                }}
            >
                {drawer}
            </CustomDrawer>
            <CustomDrawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                    },
                }}
                // open
                anchor="left"
            >
                {drawer}
            </CustomDrawer>
        </>
    );

};

export default Drawer;